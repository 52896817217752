<template>
  <section class="tables">
    <div class="row">
      <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <div class="card-title">Les articles stockés</div>


            <nav aria-label="breadcrumb" class="float-right">
              <ol class="breadcrumb">
                <router-link to="addstocks">
                  <li class="breadcrumb-item btn btn-primary">
                    Stocker un article
                  </li>
                </router-link>
              </ol>
            </nav>


            <div class="table-responsive">
              <input
                value=""
                class="form-control search-input"
                placeholder="Recherche par produit"
                type="text"
                v-model="search"
              />
            <table class="table">
              <thead>
                <tr>
                  <th>Reference produit</th>
                  <th>Type</th>
                  <th>Quantités stocké</th>

                  <th>Quantités minimales</th>
                  <th>Emplacements</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(produit, key) in searchFunction" :key="key">
                  <th>{{ produit.reference }}</th>
                  <td>{{ produit.categorie }}</td>

                  <th>{{ produit.quantites_disponible }}</th>
                  <td>{{ produit.quantites_minimal }}</td>

                  <th>
                        <i
                          class="mdi mdi-eye icone text-info"
                          title="Afficher les détails"
                          data-toggle="modal"
                          :data-target="'#test' + produit._id"
                        >
                        </i>

                        <div
                          class="modal large"
                          :id="'test' + produit._id"
                          tabindex="-1"
                          role="dialog"
                          aria-labelledby="exampleModalLabel"
                          aria-hidden="true"
                        >
                          <div class="modal-dialog modal-lg" role="document">
                            <div class="modal-content">
                              <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalLabel">
                                  <span style="font-size: 13px"
                                    >Produit No =</span
                                  >
                                  <b>{{ produit.reference }}</b>
                                </h5>
                                <button
                                  type="button"
                                  class="close"
                                  data-dismiss="modal"
                                  aria-label="Close"
                                >
                                  <span aria-hidden="true">&times;</span>
                                </button>
                              </div>
                              <div class="modal-body">
                                <div
                                  style="margin-top: 20px; margin-bottom: 20px"
                                >
                                  <h5>Quantités totales :<b>{{produit.quantites_disponible}}</b></h5>
                                </div>
                                <table class="table table-bordered">
                                  <thead>
                                    <tr>
                                      <th>Code Emplacement</th>
                                      <th>Quantités</th>
                                    </tr>
                                  </thead>

                                  <tbody>
                                    <tr
                                      v-for="(
                                        emplacement, index
                                      ) in produit.stocks"
                                      :key="index"
                                    >
                                      <td>
                                        #{{ emplacement.code_emplacement }}
                                      </td>

                                      <td>{{ emplacement.quantites }}</td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                              <!-- <div class="modal-footer">
                                  <button
                                    type="button"
                                    class="btn btn-secondary"
                                    data-dismiss="modal"
                                  >
                                    Close
                                  </button>
                                  <button type="button" class="btn btn-primary">
                                    Save changes
                                  </button>
                                </div> -->
                            </div>
                          </div>
                        </div>
                      
                  </th>
                  
                  
                  
                  
                  <td>
                    <i
                        class="mdi mdi-pen icone text-success"
                        style="color: green"
                      ></i> 
                    <i
                      class="mdi mdi-delete icone text-danger"
                      style="color: red"
                    
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { HTTP } from '@/axios';
import Swal from "sweetalert2";
import Toast from "sweetalert2";
export default {
  data() {
    return {
      stocks: [],
      search:'',
      emplacement:''
    };
  },  

    computed: {
    searchFunction() {
      return this.stocks.filter((item) => {
        return (
          item.reference
            .toLowerCase()
            .indexOf(this.search.toLowerCase()) > -1
        );
      });
    },
  },

  created() {
    this.getstocks();
  },
  methods: {
    
    oneoemplacement(id) {
      HTTP
        .get("emplacements/getoneemplacement/" +id)
        .then((response) => {
          this.emplacement = response.data;
        })
        .then((err) => console.log(err));
    },


    getstocks() {
   HTTP.get("stocks/getstockcollections")
        .then((response) => {
          this.stocks = response.data;
      })
        .then((err) => console.log(err));

    },

    deletstocké(id) {
      Swal.fire({
        title: "Êtes-vous sûrs ?",
        text: "Vous voulez supprimé le client",
        icon: "warning",
        cancelButtonText: "Annuler",
        showCancelButton: true,
        confirmButtonColor: "#3e884f",
        cancelButtonColor: "#d33",
        confirmButtonText: "Oui, valider!",
      }).then((result) => {
        if (result.isConfirmed) {
          HTTP
            .delete("deleteclient/" + id)
            .then((response) => {
              //this.$htmlToPaper("printNow");

              Toast.fire({
                position: "top-center",
                icon: "success",
                title: "Objectif supprimé",
              });

              this.getstocks();
            });
        }
      });
    },
  },
};
</script>
